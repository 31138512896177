/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './swarm-disaster.scss';
import { Row, Col, Tab, Table, Tabs, Card } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';

const HSRGuidesSwarm: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page swarm'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Swarm Disaster Guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_swarm.jpg"
            alt="Swarm Disaster"
          />
        </div>
        <div className="page-details">
          <h1>Swarm Disaster Guide</h1>
          <h2>
            This guide that will help you efficiently clear the Swarm Disaster
            by giving tips about characters, teams, paths and blessing you
            should use.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Swarm Disaster" />
        <StaticImage
          src="../../../images/starrail/generic/swarm.jpg"
          alt="Swarm Disaster"
        />
        <p>
          <strong>
            Swarm Disaster is an expansion to the Simulated Universe mode
            available in Honkai: Star Rail
          </strong>{' '}
          that introduces new enemies, Paths and a lot of rewards. You can
          access the mode in the SU main menu and while the premise of the mode
          is similar to the base one - with its roguelite nature -{' '}
          <strong>
            it's actually a lot harder and can be considered the hardest content
            available in the game.
          </strong>
        </p>
        <p>
          Partially because on the highest difficulty (there are 5) available in
          the Swarm Disaster you will fight against{' '}
          <strong>level 95 enemies</strong>. To compare to, in the Memory of
          Chaos, the last stage features level 90 enemies which translates to
          them having more HP, but also you doing less damage since the damage
          formula takes into account the difference between your character
          levels and the enemy you face.
        </p>
        <p>
          The Swarm Disaster mode features a{' '}
          <strong>separate progression and skill tree</strong> that gives your
          characters free stats for doing various challenges - which is another
          change compared to the base Simulated Universe skill tree, where you
          just had to play the mode to unlock the nodes. And{' '}
          <strong>
            we strongly suggest to focus on progressing down the tree
          </strong>
          , because it will make the mode much easier. You can check all
          available bonuses you can obtain there further down the guide in
          section dedicated to them.
        </p>
        <p>
          On top of great rewards that you can obtain in the mode (
          <strong>including 4500 Stellar Jades</strong>) you will also be able
          to unlock the <strong>Path of Propagation</strong> in the base
          Simulated Universe. The Path enhances the Basic Attacks and also helps
          with Skill Point management which makes it perfect for the recently
          released Imbibitor Lunae and Qingque.
        </p>
        <SectionHeader title="Paths" />
        <StaticImage
          src="../../../images/starrail/generic/swarm_paths.jpg"
          alt="Swarm Disaster"
        />
        <p>
          When you first enter the Swarm Disaster mode, you will have to pick
          one of the available Paths and similar to the base Simulated Universe
          not all Paths are unlocked when you start playing the mode and you
          need to progress through the difficulties and story to gain access to
          them. At first, you will only have access to the Destruction path
          (during tutorial) and shortly after you will unlock Preservation,
          Abundance and Hunt Paths.
        </p>
        <p>
          The Path you choose will not only affect the Blessings you will want
          to focus on and your playstyle, but it will also impact how you
          interact with the Board and Domains -{' '}
          <strong>so the main mechanism of the Swarm Disaster mode</strong>.
        </p>
        <StaticImage
          src="../../../images/starrail/generic/swarm_tiles.jpg"
          alt="Swarm Disaster"
        />
        <br /> <br />
        <h5>Available Paths</h5>
        <p>Here's a list of all available Paths and their bonuses:</p>
        <div className="paths-details">
          <div className="single-path box">
            <h5 className="path-name elation">
              <div className="inline-path elation">
                <StaticImage
                  src="../../../images/starrail/icons/path_elation.png"
                  alt="Elation"
                />
                <span>Elation</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second Plane begins, surprising effects will
                be applied.
              </p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                When allies enter "Domain - Boss" or "Domain - Boss: The Swarm",
                obtain 1 random Happiness. For every domain that allies pass
                through, the Follow-up attack DMG dealt by all allies receive
                random stat increases.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                The Elation Path is perfect for characters with Follow-up
                attacks. Moving through the game board will grant you a
                permanently stacking Follow-up attack damage bonus - though keep
                in mind that the amount this bonus increases per movement is
                random, so there's a RNG element tied to it. Use your Dice to
                apply Happiness to tiles with undesirable domains - Happiness
                has a chance to transform tiles positively e.g. into loot
                domains or negatively e.g. into Swarm Domains.
              </p>
              <p className="sub-title">Characters</p>
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="clara" enablePopover />
                <HSRCharacter mode="icon" slug="jing-yuan" enablePopover />
                <HSRCharacter mode="icon" slug="herta" enablePopover />
              </div>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name destruction">
              <div className="inline-path destruction">
                <StaticImage
                  src="../../../images/starrail/icons/path_destruction.png"
                  alt="Destruction"
                />
                <span>Destruction</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plane begins, turns 2 Domain(s) into
                Domain - Elite.
              </p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                After achieving victory in Domain - Elite, obtain an extra 1
                Blessing(s). For every battle won, increases DMG dealt by all
                allies by 8%.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                Destruction is generic Path that offers global DMG% increase for
                your entire team based on how many individual enemies you slay
                within the entire run. Focus on moving to Combat Domains to
                stack up your continuous bonus and use enhancement Dice to
                upgrade Elite Combat Domains you intend to path towards to. When
                rolling your Legendary Dice use it on an Elite - preferably one
                that is enhanced saving you time and allowing you to take a
                different route with more opportunities.
              </p>
              <p className="sub-title">Characters</p>
              <p>Any character can work here.</p>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name hunt">
              <div className="inline-path hunt">
                <StaticImage
                  src="../../../images/starrail/icons/path_hunt.png"
                  alt="Hunt"
                />
                <span>Hunt</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plane begins, generates 1 extra
                Blessing Trotter and 1 Curio Trotter beacons.
              </p>
              <p>
                Blessing Trotter: There will be a Trotter with this Beacon in
                the Domain. Defeat it to obtain additional Blessings.
              </p>
              <p>
                Curio Trotter: There will be a Trotter with this Beacon in the
                Domain. Defeat it to obtain additional Curios.
              </p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                Every time allies move to a Domain, all Domains currently with
                the Blessing Trotter or Curio Trotter will swap their positions
                with an adjacent Domain. For every Trotter defeated in battle,
                all allies' CRIT DMG increases by 30%.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                The Hunt Path is the strongest offensive Path for characters
                that can utilize critical strikes. Slaying Warp Trotter
                encounters will grant you stacking Crit DMG bonus, so focus on
                using your dice to generate, lock down and move towards Warp
                Trotter domains - but be aware that the little buggers don't
                like to stay in one place and will teleport around the board.
              </p>
              <p className="sub-title">Characters</p>
              <p>Any crit-based character can work here.</p>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name abundance">
              <div className="inline-path abundance">
                <StaticImage
                  src="../../../images/starrail/icons/path_abundance.png"
                  alt="Abundance"
                />
                <span>Abundance</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plane begins, applies Mercy to 2 random
                Domain(s).
              </p>
              <p>
                When using Audience Die: Abundance in domains with Mercy effect
                applies, the countdown timer will no longer be reduced and have
                1 added to it instead. This continuous effect will conclude
                after triggering Planar Disarray.
              </p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                Countdown is reduced by 2 extra count(s). When entering Domains
                with the Mercy effect, this effect is changed to adding 1
                counter(s) to the Countdown. Every Countdown counter increases
                all allies' Outgoing healing by 5%.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                Abundance is a passive path focused on gathering many more
                Blessings than any other Path thanks to the ability of
                completely ignoring the countdown. For each stack of countdown
                remaining, players will obtain increased % Healing, offering a
                large initial defensive bonus compared to other paths. Focus on
                spreading as much Mercy as possible and only navigating through
                domains with Mercy already on them. Be aware that choosing a
                domain without Mercy will cost 3 countdown but choosing one with
                Mercy will add 1 countdown to your remaining total.
              </p>
              <p>
                Due to the nature of the Path, it's a generic path that can work
                with any damage dealer type as long as you have a Healer in the
                team (which you will need either way). It's slower than others,
                but unless your characters will be one-shot, you will -
                eventually - conquer the mode.
              </p>
              <p className="sub-title">Characters</p>
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="luocha" enablePopover />
                <HSRCharacter mode="icon" slug="bailu" enablePopover />
                <HSRCharacter mode="icon" slug="natasha" enablePopover />
              </div>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name nihility">
              <div className="inline-path nihility">
                <StaticImage
                  src="../../../images/starrail/icons/path_nihility.png"
                  alt="Nihility"
                />
                <span>Nihility</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plan begins, turn 2 random Domain(s)
                into "Domain - Blank".
              </p>
              <p>Domain - Blank: Nothing will happen in this Domain.</p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                A "Domain - Blank" will be replaced with a Domain of a random
                type when allies enter it. For every "Domain - Blank" that
                allies pass through, DoT dealt by all allies increases by 18%.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                The Nihility path is ideal for Damage over Time compositions
                offering a stacking continuous effect that boosts DoTs
                massively. Re-roll your Dice aggressively to convert as many
                domains into Blank Domains with the goal of converting the
                majority of Domains.
              </p>
              <p>
                Activating conversion Dice outcomes will remove that option from
                being rolled and once all of Domains are converted the only
                remaining Dice options will be your legendary effect and one
                other granting you massive rewards in which you should
                priorities moving through blank tiles at this time.
              </p>
              <p className="sub-title">Characters</p>
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="kafka" enablePopover />
                <HSRCharacter mode="icon" slug="sampo" enablePopover />
                <HSRCharacter mode="icon" slug="serval" enablePopover />
                <HSRCharacter mode="icon" slug="luka" enablePopover />
              </div>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name remembrance">
              <div className="inline-path remembrance">
                <StaticImage
                  src="../../../images/starrail/icons/path_remem.png"
                  alt="Remembrance"
                />
                <span>Remembrance</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plane begins, randomly copy 2 Domain(s)
                and apply Recollection to it.
              </p>
              <p>
                Recollection: When using Audience Die: Remembrance, increases
                the Effect Hit Rate of all allies when passing through any
                Domain - Recollection.
              </p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                Every time allies pass through 1 Domain with Recollection
                applied, Effect Hit Rate for all allies increase by 25%.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                The Path is best for characters that aim to crowd control
                enemies indefinitely with Freeze, Entangle or Imprison thanks to
                a stacking Effect Hit Rate buff that will increase your chances
                of landing these effects in succession.
              </p>
              <p>
                Use you Recollection Dice effect to copy high value Domains such
                as Reward, Adventure and Elite to adjacent domains to increase
                the rewards of your path throughout the plane. Ensure you
                prioritize moving through Recollection domains to stack your
                Effect Hit Rate buff.
              </p>
              <p className="sub-title">Characters</p>
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="march-7th" enablePopover />
              </div>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name preservation">
              <div className="inline-path preservation">
                <StaticImage
                  src="../../../images/starrail/icons/path_preservation.png"
                  alt="Preservation"
                />
                <span>Preservation</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plane begins, apply Cornerstone to 2
                random Domain(s).
              </p>
              <p>
                Cornerstone: When using Audience Die: Preservation in Domains
                with Cornerstone applied, the Domain will not Collapse. However
                the Cornerstone effect will be removed after entering said
                Domains.
              </p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                When entering Domain - Boss or Domain - Boss: The Swarm, obtain
                rewards based on the number of Domains with the Cornerstone
                effect in the current plane. For every Domain with Cornerstone,
                increase the amount of Shield Effect received by all allies by
                4%. For every 1 different types of Domains with Cornerstone,
                obtain a Blessing.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                The Path is the strongest Defensive Path great for pushing
                content that is incredibly dangerous, but you need to use
                characters that create Shields. Focus on applying Cornerstones
                to as many different Domain types as possible using your Dice
                effects.
              </p>
              <p>
                When you reach the Boss of the plane, depending on how many
                different Domains you have applied Cornerstone to you will be
                granted a number of Blessings as well as a large bonus to total
                Shielding amount based on the amount of all Cornerstones you
                built.
              </p>
              <p className="sub-title">Characters</p>
              <div className="employee-container for-nikke">
                <HSRCharacter mode="icon" slug="gepard" enablePopover />
                <HSRCharacter mode="icon" slug="march-7th" enablePopover />
                <HSRCharacter
                  mode="icon"
                  slug="trailblazer-preservation"
                  enablePopover
                />
              </div>
            </div>
          </div>
          <div className="single-path box">
            <h5 className="path-name propagation">
              <div className="inline-path propagation">
                <StaticImage
                  src="../../../images/starrail/icons/path_propa.png"
                  alt="Propagation"
                />
                <span>Propagation</span>
              </div>
            </h5>
            <div className="path-content">
              <p className="sub-title">Dimension Effect</p>
              <p>
                When the first or second plane begins, turn 2 random domains
                into "Domain - Combat: The Swarm" and "Domain - Occurrence: The
                Swarm".
              </p>
              <p></p>
              <p className="sub-title">Continuous Effect</p>
              <p>
                When allies enter "Domain - Combat: The Swarm" or "Domain -
                Occurance: The Swarm", they will receive a special effect of
                Propagation. For every "Domain - Combat: The Swarm" or "Domain -
                Occurance: The Swarm" that allies pass through, all allies'
                Basic ATK DMG increases by 20%.
              </p>
              <p className="sub-title">Our Review</p>
              <p>
                The Path is tailored toward the recently released Imbibitor
                Lunae and Qingque as it increased the damage done by Basic
                Attacks.
              </p>
              <p>
                Focus on navigating toward "Swarm" related Domains on the board
                - defeat them to stack the Basic ATK DMG % buff as much as
                possible. Use your Audience Dice to spread Swarm Domains to
                adjacent Domains to make it easier to gather more of them.
              </p>
              <p className="sub-title">Characters</p>
              <div className="employee-container for-nikke">
                <HSRCharacter
                  mode="icon"
                  slug="imbibitor-lunae"
                  enablePopover
                />
                <HSRCharacter mode="icon" slug="qingque" enablePopover />
              </div>
            </div>
          </div>
        </div>
        <h5>The best Path</h5>
        <p>
          There is no one best Path that will work equally well for everyone.
          Each account is different and this will dictate which Path you should
          play - below you will find some general tips for the Path you should
          choose:
        </p>
        <div className="inline-path abundance list">
          <StaticImage
            src="../../../images/starrail/icons/path_abundance.png"
            alt="Abundance"
          />
          <span>Abundance</span>
        </div>
        <p>
          A great entry-level Path that will work with any account - and its
          special mechanic will allow you to gather more Blessings than any
          other Path, making your team much stronger. And since you only need a
          Healer in your team to make use of it, it's easy to use and very
          reliable. We found this Path to be the best one to tackle the dreaded
          difficulty 5.
        </p>
        <div className="inline-path preservation list">
          <StaticImage
            src="../../../images/starrail/icons/path_preservation.png"
            alt="Preservation"
          />
          <span>Preservation</span>
        </div>
        <p>
          An alternative entry-level Path that this time focuses on Shielding.
          While you can make it work with the Fire Trailblazer, it really starts
          to shine with Gepard as his Shields are simply much stronger - and
          they will turn this defensive Path into an offensive monstrosity. Its
          special mechanic also will allow you to gain more Blessings than most
          other Paths (but less than Abundance).
        </p>
        <div className="inline-path nihility list">
          <StaticImage
            src="../../../images/starrail/icons/path_nihility.png"
            alt="Nihility"
          />
          <span>Nihility</span>
        </div>
        <p>
          If you have Kafka, this Path is the best choice for you. Nihility Path
          already is one of the strongest choices for base Simulated Universe
          because its Blessings are super strong and the same thing can be said
          about its Performance in the Swarm Disaster. Still, you can try to
          make it work without Kafka by using Sampo or Serval, but Kafka just
          elevates it to another level.
        </p>
        <div className="inline-path hunt list">
          <StaticImage
            src="../../../images/starrail/icons/path_hunt.png"
            alt="Hunt"
          />
          <span>Hunt</span>
        </div>
        <p>
          Offense is best defense is a common saying and it can also work well
          in the Swarm Disaster - still, while on lower difficulties this Path
          will allow you to speedrun the planes, on the higher ones its
          offensive power might not be enough to safely auto and chill. So use
          this Path cautiously.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Interplay" />
        <p>
          Interplay is another new feature that allows you to combine two
          different Paths to make the Resonance skill much stronger.
        </p>
        <h5>How to activate Interplay?</h5>
        <p>
          First, you need to activate the Resonance for your main Path by
          gathering 3 Blessings from that Path. Then you need to gather 3
          Blessings from the Path you want to interplay with - this will
          automatically activate the Interplay bonus.{' '}
          <strong>Important!</strong> Each Path can be combined with two other
          paths, but the combinations differ based on your Primary Path (and
          yes, you can activate both Interplay paths in the same run - by simply
          gathering 3 Blessings from each secondary Path).
        </p>
        <p>
          The below table showcases all combinations and the bonuses you can
          obtain.
        </p>
        <Table striped bordered responsive="sm" className="interplay-info">
          <thead>
            <tr>
              <th className="with-path">Primary Path</th>
              <th className="with-path">Secondary Path</th>
              <th>Special Interplay Effect</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="with-path">
                <div className="inline-path elation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_elation.png"
                    alt="Elation"
                  />
                  <span>Elation</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path remembrance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_remem.png"
                    alt="Remembrance"
                  />
                  <span>Remembrance</span>
                </div>
              </td>
              <td>
                When Path Resonance: Elation deals Ice DMG, there is a{' '}
                <strong>150%</strong> base chance to Freeze the enemy target for{' '}
                <strong>1</strong> turn(s).
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path elation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_elation.png"
                    alt="Elation"
                  />
                  <span>Elation</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path abundance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_abundance.png"
                    alt="abundance"
                  />
                  <span>Abundance</span>
                </div>
              </td>
              <td>
                When Path Resonance: Elation deals DMG, randomly dispels{' '}
                <strong>1</strong> debuff on <strong>1</strong> ally.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path destruction">
                  <StaticImage
                    src="../../../images/starrail/icons/path_destruction.png"
                    alt="Destruction"
                  />
                  <span>Destruction</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path preservation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_preservation.png"
                    alt="preservation"
                  />
                  <span>Preservation</span>
                </div>
              </td>
              <td>
                Using Path Resonance will cause any character with current HP
                percentage lower than 50% to gain a Shield equal to{' '}
                <strong>40%</strong> of their Max HP, lasting for{' '}
                <strong>2</strong> turn(s).
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path destruction">
                  <StaticImage
                    src="../../../images/starrail/icons/path_destruction.png"
                    alt="Destruction"
                  />
                  <span>Destruction</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path elation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_elation.png"
                    alt="Elation"
                  />
                  <span>Elation</span>
                </div>
              </td>
              <td>
                When a character launches a follow-up attack, regenerates{' '}
                <strong>5%</strong> Energy for Path Resonance. If the
                character's current HP percentage is lower than 50%,
                additionally regenerates <strong>5%</strong> Energy for Path
                Resonance.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path hunt">
                  <StaticImage
                    src="../../../images/starrail/icons/path_hunt.png"
                    alt="hunt"
                  />
                  <span>Hunt</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path abundance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_abundance.png"
                    alt="abundance"
                  />
                  <span>Abundance</span>
                </div>
              </td>
              <td>
                After enemy target(s) are defeated by a Path Resonance, restores
                all allies' HP equal to <strong>50%</strong> of their respective
                Max HP.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path hunt">
                  <StaticImage
                    src="../../../images/starrail/icons/path_hunt.png"
                    alt="hunt"
                  />
                  <span>Hunt</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path elation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_elation.png"
                    alt="Elation"
                  />
                  <span>Elation</span>
                </div>
              </td>
              <td>
                After using Path Resonance, all allies' SPD increases by{' '}
                <strong>25</strong> for <strong>2</strong> turn(s). This effect
                also applies to units summoned by allies.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path abundance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_abundance.png"
                    alt="abundance"
                  />
                  <span>Abundance</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path nihility">
                  <StaticImage
                    src="../../../images/starrail/icons/path_nihility.png"
                    alt="nihility"
                  />
                  <span>Nihility</span>
                </div>
              </td>
              <td>
                After using Path Resonance, all allies' Weakness Break
                Efficiency increases by <strong>15%</strong> and Break Effect
                increases by <strong>50%</strong>. This effect lasts for 2
                turn(s) and stacks up to 2 time(s).
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path abundance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_abundance.png"
                    alt="abundance"
                  />
                  <span>Abundance</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path propagation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_propa.png"
                    alt="Propagation"
                  />
                  <span>Propagation</span>
                </div>
              </td>
              <td>
                After a character has their HP restored via Path Resonance and
                if the healing amount exceeds the maximum HP, recover{' '}
                <strong>1</strong> Skill Point. This effect can only trigger 1
                time per Path Resonance use. After triggering this effect, all
                DMG dealt by all allies is increased by <strong>30%</strong> for
                2 turns. This effect can stack up to 2 time(s).
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path nihility">
                  <StaticImage
                    src="../../../images/starrail/icons/path_nihility.png"
                    alt="nihility"
                  />
                  <span>Nihility</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path hunt">
                  <StaticImage
                    src="../../../images/starrail/icons/path_hunt.png"
                    alt="hunt"
                  />
                  <span>Hunt</span>
                </div>
              </td>
              <td>
                After using a Path Resonance, for every type of debuff (out of
                Burn, Shock, Bleed, and Wind Shear) the enemy target is
                currently inflicted with, Advance Forward all allies' actions by{' '}
                <strong>4%</strong>.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path nihility">
                  <StaticImage
                    src="../../../images/starrail/icons/path_nihility.png"
                    alt="nihility"
                  />
                  <span>Nihility</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path propagation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_propa.png"
                    alt="Propagation"
                  />
                  <span>Propagation</span>
                </div>
              </td>
              <td>
                A character's Basic ATK can cause all DoTs currently applied on
                the target via Path Resonance: Nihility to immediately deal DMG
                equivalent to <strong>40%</strong> of these statuses' original
                DMG.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path remembrance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_remem.png"
                    alt="Remembrance"
                  />
                  <span>Remembrance</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path hunt">
                  <StaticImage
                    src="../../../images/starrail/icons/path_hunt.png"
                    alt="hunt"
                  />
                  <span>Hunt</span>
                </div>
              </td>
              <td>
                When the Freeze effect caused by Path Resonance is dispelled or
                resisted by enemy targets, there is a <strong>150%</strong> base
                chance of decreasing the target's SPD by <strong>15%</strong>{' '}
                for 2 turn(s).
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path remembrance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_remem.png"
                    alt="Remembrance"
                  />
                  <span>Remembrance</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path destruction">
                  <StaticImage
                    src="../../../images/starrail/icons/path_destruction.png"
                    alt="Destruction"
                  />
                  <span>Destruction</span>
                </div>
              </td>
              <td>
                When the Freeze effect caused by Path Resonance is dispelled or
                resisted by enemy targets, there is a <strong>150%</strong> base
                chance of lowering the target's All-Type RES by{' '}
                <strong>15%</strong> for 2 turn(s). For every 1% HP that each
                ally has lost, the target's All-Type RES is additionally lowered
                by <strong>0.06%</strong>.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path preservation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_preservation.png"
                    alt="preservation"
                  />
                  <span>Preservation</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path nihility">
                  <StaticImage
                    src="../../../images/starrail/icons/path_nihility.png"
                    alt="nihility"
                  />
                  <span>Nihility</span>
                </div>
              </td>
              <td>
                There is a <strong>150%</strong> base chance for Path Resonance:
                Preservation to inflict Bleed on the attacked target for 2
                turn(s). Bleeding enemies will take Physical DoT equal to{' '}
                <strong>12%</strong> of their Max HP at the start of each turn.
                This DMG cannot exceed 250% of the total amount of Shield effect
                currently active on all allies.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path preservation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_preservation.png"
                    alt="preservation"
                  />
                  <span>Preservation</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path remembrance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_remem.png"
                    alt="Remembrance"
                  />
                  <span>Remembrance</span>
                </div>
              </td>
              <td>
                When attacking Frozen enemy targets with Path Resonance:
                Preservation, the DMG dealt increases by <strong>80%</strong>{' '}
                and the Freeze status on the enemy targets is removed.
                Dissociation will not be removed by this effect.
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path propagation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_propa.png"
                    alt="Propagation"
                  />
                  <span>Propagation</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path preservation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_preservation.png"
                    alt="preservation"
                  />
                  <span>Preservation</span>
                </div>
              </td>
              <td>
                When using Path Resonance, a single target ally gain a Shield
                equal to <strong>60%</strong> of Max HP, lasting for 3 turn(s).
              </td>
            </tr>
            <tr>
              <td className="with-path">
                <div className="inline-path propagation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_propa.png"
                    alt="Propagation"
                  />
                  <span>Propagation</span>
                </div>
              </td>
              <td className="with-path">
                <div className="inline-path destruction">
                  <StaticImage
                    src="../../../images/starrail/icons/path_destruction.png"
                    alt="Destruction"
                  />
                  <span>Destruction</span>
                </div>
              </td>
              <td>
                After using Path Resonance, nullifies all DMG received by a
                single target ally except DoT. This status is dispelled after
                being attacked. If the target ally's current HP percentage is
                lower than 50% when Path Resonance is used, it will additionally
                recover 1 Skill Point(s).
              </td>
            </tr>
          </tbody>
        </Table>
        <SectionHeader title="Communing Trail" />
        <p>
          Communing Trail is the screen that shows the current bonuses you have
          already unlocked via the Trail of Pathstrider (the system of
          challenges in Swarm Disaster). The bonuses are split into 7 Paths and
          below you can find a list of them and the summary of buffs within a
          path.
        </p>
        <div className="swarm-paths">
          <Tabs defaultActiveKey="elation" id="bonuses" transition={false}>
            <Tab
              eventKey="elation"
              title={
                <div className="inline-path elation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_elation.png"
                    alt="Elation"
                  />
                  <span>Elation</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Follow-Up</span> attack damage increases
                      by <strong>16%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>CRIT DMG</span> increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      Every time allies enter the next plane in the Simulated
                      Universe: The Swarm Disaster, gain <strong>1</strong>.
                      more chance to re-roll the die.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Follow-Up</span> attack damage increases
                      by <strong>16%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>CRIT DMG</span> increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Follow-Up</span> attack damage increases
                      by <strong>16%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>CRIT DMG</span> increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>Follow-Up DMG</strong> +48%
                </li>
                <li>
                  <strong>Resonance DMG</strong> +12%
                </li>
                <li>
                  <strong>Crit DMG</strong> +30%
                </li>
              </ul>
            </Tab>
            <Tab
              eventKey="destruction"
              title={
                <div className="inline-path destruction">
                  <StaticImage
                    src="../../../images/starrail/icons/path_destruction.png"
                    alt="Destruction"
                  />
                  <span>Destruction</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>ATK</span> increases by{' '}
                      <strong>12%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase
                      the current Energy of Path Resonance by{' '}
                      <strong>10</strong> every time allies enter battle.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' receive <strong>5%</strong>{' '}
                      <span>less damage</span>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      If you passed the First Plane in your previous Simulated
                      Universe: The Swarm Disaster challenge, then all enemies
                      will receive a set amount of DMG equal to 99% of their
                      respective Max HP when you enter battle for the first 4
                      battles (excluding Boss battles) in the First Plane in the
                      current The Swarm Disaster challenge.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>ATK</span> increases by{' '}
                      <strong>12%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' receive <strong>5%</strong>{' '}
                      <span>less damage</span>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>ATK</span> increases by{' '}
                      <strong>12%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' receive <strong>5%</strong>{' '}
                      <span>less damage</span>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>ATK</strong> +36%
                </li>
                <li>
                  <strong>Resonance DMG</strong> +6%
                </li>
                <li>
                  <strong>Damage Taken</strong> -15%
                </li>
              </ul>
            </Tab>
            <Tab
              eventKey="hunt"
              title={
                <div className="inline-path hunt">
                  <StaticImage
                    src="../../../images/starrail/icons/path_hunt.png"
                    alt="hunt"
                  />
                  <span>Hunt</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>SPD</span> increases by{' '}
                      <strong>4%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>CRIT Rate</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      When entering Domain - Boss or Domain - Boss: The Swarm in
                      the Simulated Universe: The Swarm Disaster, all allies
                      regenerate their respective Energy to Max Energy.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>SPD</span> increases by{' '}
                      <strong>4%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>CRIT Rate</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>SPD</span> increases by{' '}
                      <strong>4%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>CRIT Rate</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>SPD</strong> +12%
                </li>
                <li>
                  <strong>Resonance DMG</strong> +12%
                </li>
                <li>
                  <strong>Crit Rate</strong> +18%
                </li>
              </ul>
            </Tab>
            <Tab
              eventKey="abundance"
              title={
                <div className="inline-path abundance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_abundance.png"
                    alt="abundance"
                  />
                  <span>Abundance</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Max HP</span> increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase
                      the current Energy of Path Resonance by{' '}
                      <strong>10</strong> every time allies enter battle.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>HP Recovery</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      Increase initial countdown counter by <strong>2</strong>{' '}
                      in the Simulated Universe: The Swarm Disaster.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Max HP</span> increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase
                      the current Energy of Path Resonance by{' '}
                      <strong>10</strong> every time allies enter battle.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>HP Recovery</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Max HP</span> increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>HP Recovery</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>Max HP</strong> +30%
                </li>
                <li>
                  <strong>HP Recovery</strong> +18%
                </li>
              </ul>
            </Tab>
            <Tab
              eventKey="nihility"
              title={
                <div className="inline-path nihility">
                  <StaticImage
                    src="../../../images/starrail/icons/path_nihility.png"
                    alt="nihility"
                  />
                  <span>Nihility</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>DoT</span> dealt increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Break Effect</span> increases by{' '}
                      <strong>15%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, the Cosmic
                      Fragments received when giving up the choice of the dice
                      increases by <strong>10</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>DoT</span> dealt increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Break Effect</span> increases by{' '}
                      <strong>15%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>DoT</span> dealt increases by{' '}
                      <strong>10%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Break Effect</span> increases by{' '}
                      <strong>15%</strong>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>Break Effect</strong> +45%
                </li>
                <li>
                  <strong>Resonance DMG</strong> +12%
                </li>
                <li>
                  <strong>Damage over Time</strong> +30%
                </li>
              </ul>
            </Tab>
            <Tab
              eventKey="remembrance"
              title={
                <div className="inline-path remembrance">
                  <StaticImage
                    src="../../../images/starrail/icons/path_remem.png"
                    alt="Remembrance"
                  />
                  <span>Remembrance</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      enemies' <span className="enemy">Effect RES</span>{' '}
                      decreases by <strong>3%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase
                      the current Energy of Path Resonance by{' '}
                      <strong>10</strong> every time allies enter battle
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Effect Hit Rate</span> increases by{' '}
                      <strong>5%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      Unlock the Cheat Function in the simulated Universe: The
                      Swarm Disaster. Specify a Die Effect and immediately
                      obtain that effect after re-rolling. At the start of the
                      challenge, receive <strong>1</strong> chance(s) to cheat.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      enemies' <span className="enemy">Effect RES</span>{' '}
                      decreases by <strong>3%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, Path
                      Resonance DMG increases by <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Effect Hit Rate</span> increases by{' '}
                      <strong>5%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      enemies' <span className="enemy">Effect RES</span>{' '}
                      decreases by <strong>3%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>Effect Hit Rate</span> increases by{' '}
                      <strong>5%</strong>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>Effect Hit Rate</strong> +15%
                </li>
                <li>
                  <strong>Resonance DMG</strong> +6%
                </li>
                <li>
                  <strong>Enemy Effect RES</strong> -9%
                </li>
              </ul>
            </Tab>
            <Tab
              eventKey="Preservation"
              title={
                <div className="inline-path preservation">
                  <StaticImage
                    src="../../../images/starrail/icons/path_preservation.png"
                    alt="preservation"
                  />
                  <span>Preservation</span>
                </div>
              }
            >
              <Table
                striped
                bordered
                responsive="sm"
                className="hsr-stat-table"
              >
                <thead>
                  <tr>
                    <th className="first">Communing</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="first">
                      <strong>1</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>DEF</span> increases by{' '}
                      <strong>12%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>3</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>5</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase{' '}
                      <span>Shield Effects</span> received by all characters by{' '}
                      <strong>4%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>7</strong>
                    </td>
                    <td>
                      Increases the cosmic Fragments received at the start of
                      Simulated Universe: The Swarm Disaster by{' '}
                      <strong>100</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>9</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>DEF</span> increases by{' '}
                      <strong>12%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>11</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster,{' '}
                      <span>Path Resonance DMG</span> increases by{' '}
                      <strong>6%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>13</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase{' '}
                      <span>Shield Effects</span> received by all characters by{' '}
                      <strong>4%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>16</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, all
                      characters' <span>DEF</span> increases by{' '}
                      <strong>12%</strong>.
                    </td>
                  </tr>
                  <tr>
                    <td className="first">
                      <strong>20</strong>
                    </td>
                    <td>
                      In the Simulated Universe: The Swarm Disaster, increase{' '}
                      <span>Shield Effects</span> received by all characters by{' '}
                      <strong>4%</strong>.
                    </td>
                  </tr>
                </tbody>
              </Table>
              <h5>Summary</h5>
              <ul>
                <li>
                  <strong>DEF</strong> +36%
                </li>
                <li>
                  <strong>Resonance DMG</strong> +12%
                </li>
                <li>
                  <strong>Shield Effect</strong> +12%
                </li>
              </ul>
            </Tab>
          </Tabs>
        </div>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Trail of Pathstrider Missions" />
        <p>
          This section will give you tips how to the missions you need to obtain
          Communing Trial buffs and also unlock the Propagation Path.
        </p>
        <Table striped bordered responsive className="banner-history">
          <thead>
            <tr>
              <th>Mission</th>
              <th>Goal</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>#1</td>
              <td>Obtain 750 Cosmic Fragments</td>
              <td>
                It's pretty simple - you will do this mission by just playing
                the mode and obtain the necessary amount of CF in 1-3 runs.
              </td>
            </tr>
            <tr>
              <td>#2</td>
              <td>Obtain a total of 10 enhanced Blessings</td>
              <td>
                You can either enhance the Blessings manually using Herta or
                gather them from tiles that have the 'enhanced' icon on them
                (Combat or Swarm).
              </td>
            </tr>
            <tr>
              <td>#3</td>
              <td>Complete a total of 6 Combat domains</td>
              <td>Hunt for those Combat domains!</td>
            </tr>
            <tr>
              <td>#4</td>
              <td>Trigger Planar Disarray 2 times</td>
              <td>
                <strong>Difficulty 2 or higher required.</strong> Ignore the
                countdown and let it reach 0 two times to trigger this.
              </td>
            </tr>
            <tr>
              <td>#5</td>
              <td>
                Enter and complete a Transaction domain for a total of 3 times
              </td>
              <td>
                <strong>Difficulty 2 or higher required.</strong> Hunt for those
                Transaction Domains!
              </td>
            </tr>
            <tr>
              <td>#6</td>
              <td>Complete a total of 6 domains with any beacon</td>
              <td>
                <strong>Difficulty 2 or higher required.</strong> Beacon's are
                shown on the tiles in the bottom right corner. The easiest way
                to force them is using the Hunt Path as it spawns a lot of
                Trotter beacon tiles.
              </td>
            </tr>
            <tr>
              <td>#7</td>
              <td>
                Complete domains with the Blessing Trotter beacon or the Curio
                Trotter beacon for a total of 4 times
              </td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> Well, Hunt
                Path again it is - just pick it and a lot of those domains will
                spawn for you.
              </td>
            </tr>
            <tr>
              <td>#8</td>
              <td>Obtain a total of 10 Curios</td>
              <td>
                <strong>Difficulty 2 or higher required.</strong> A pretty
                simple mission. You can obtain Curios in variety of ways:
                Occurrence Domains, Reward Domains or even just buy them from
                Herta at Transaction or Respite Domains. You don't need to get
                10 curios in one run, so take it slow and you will finish the
                mission in time.
              </td>
            </tr>
            <tr>
              <td>#9</td>
              <td>Complete an Adventure domain for a total of 3 times</td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> Hunt for
                Domains that have the gamepad icon on them.
              </td>
            </tr>
            <tr>
              <td>#10</td>
              <td>Trigger Emergency for a total of 1 times</td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> A very
                annoying and random mission that may take you a few runs to
                trigger. The easiest way we found to force it is hunting for
                Swarm Domains and picking the option that will 'greatly increase
                Swarm activity' - if you do it 2-3 times in one run, it will
                trigger the Swarm Emergency event.
              </td>
            </tr>
            <tr>
              <td>#11</td>
              <td>Complete Simulated Universe: Swarm Disaster 1 time</td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> Well, it's a
                both simple and hard mission at the same time. But with the
                bonuses unlocked so far, the majority of the players should be
                able to clear the Difficulty. If you're struggling, try the
                Abundance path and take it really slow by gathering as many
                Blessings as you can.
              </td>
            </tr>
            <tr>
              <td>#12</td>
              <td>
                Generate 2 Resonance Interplays in the same challenge run for a
                total of 2 times
              </td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> We explained
                how to activate Interplay in the guide and you can find viable
                combinations there - basically you need to collect 3 Blessings
                from your main Path and then 3 Blessings each for the two other
                Paths you can Interplay with.
              </td>
            </tr>
            <tr>
              <td>#13</td>
              <td>Obtain a total of 4 Resonance Formations</td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> This is
                actually simpler than the previous mission - Resonance formation
                are the upgrades to your main Path. So continue gathering
                Blessing from it to pass this mission.
              </td>
            </tr>
            <tr>
              <td>#14</td>
              <td>
                Consecutively enter Combat, Combat: Swarm, or Elite domains for
                3 times, and repeat this action for 4 times
              </td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> Path of
                Destruction is the best Path for this mission as it can force
                spawn the Domains you need. Then just try to chain them
                together.
              </td>
            </tr>
            <tr>
              <td>#15</td>
              <td>
                Purchase 3 or more items in the Transaction domain for a total
                of 2 times
              </td>
              <td>
                <strong>Difficulty 3 or higher required.</strong> Save your
                Cosmic Fragments and only use them in the Transaction Domain -
                300 should be enough as this allows you to buy 3x white
                Blessings from Screwllum.
              </td>
            </tr>
            <tr>
              <td>#16</td>
              <td>
                Complete 1 domain with the Blessing Trotter beacon and 1 domain
                the Curio Trotter beacon in the same plane for a total of 3
                times
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> Use the Hunt
                Path and chase the piggies!
              </td>
            </tr>
            <tr>
              <td>#17</td>
              <td>
                Consecutively enter different types of domains for 4 times, and
                repeat this operation for 5 times
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> This sounds
                harder than it is - you actually can do this quest by simply
                playing normally and there's a high chance you will complete it
                without any issues due to how the planes are generated.
              </td>
            </tr>
            <tr>
              <td>#18</td>
              <td>
                Have the countdown at exactly 0 when entering the Boss: Swarm
                Domain.
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> This one is
                actually tricky. Try to use Hunt Path as it allows you to
                teleport across the planes with ease and aim to have the
                cooldown at 5 or lower when you're finishing the 2nd plane - the
                3rd plane is always small and you can manipulate the countdown
                timer there easier.
              </td>
            </tr>
            <tr>
              <td>#19</td>
              <td>
                Have the average HP percentage of the entire team be at 100%
                when passing through any domain for a total of 20 times
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> You obviously
                will use a Healer in this mode no matter the Path, but if you
                pick Abundance on top of it, it will make completing this quest
                easier - just make sure everyone is healed to 100% before you
                kill the last enemy in the Domain.
              </td>
            </tr>
            <tr>
              <td>#20</td>
              <td>
                Possess 1,000 or more Cosmic Fragments when entering a Boss:
                Swarm Domain
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> Just play
                Abundance Path and abuse the Mercy mechanic to step on as many
                domains as possible.
              </td>
            </tr>
            <tr>
              <td>#21</td>
              <td>Enter a Blank domain for a total of 12 times</td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> Just play the
                Nihility Path as it allows you to generate the Blank Domains
                every single turn.
              </td>
            </tr>
            <tr>
              <td>#22</td>
              <td>
                Obtain a total of 1000 Cosmic Fragments through means other than
                battles
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> Abundance Path
                will again help you here - navigate the domain to travel through
                as many non-Combat domains to get the CF from them.
              </td>
            </tr>
            <tr>
              <td>#23</td>
              <td>
                Have the Planar Disarray's Disruption Level at level 6 or higher
                when completing the stage
              </td>
              <td>
                <strong>Difficulty 4 or higher required.</strong> Any Path
                besides Abundance will work here as you actually want to
                decrease the Countdown really fast.
              </td>
            </tr>
            <tr>
              <td>#24</td>
              <td>
                Use 3 different Paths to complete Simulated Universe: Swarm
                Disaster at Difficulty V
              </td>
              <td>
                <strong>Difficulty 5 or higher required.</strong> Well, good
                luck!
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          As for the yellow pillar challenges - they will take you a while to
          finish as each requires 'siding' with the Aeon who governs the Path 20
          times. You can do that after dealing with the Boss of a plane via this
          screen:
        </p>
        <StaticImage
          src="../../../images/starrail/generic/special.jpg"
          alt="Swarm Disaster"
        />
        <p>
          Sadly, there's no way to track how many choices you've already made
          for each Path, so you will have to do it manually.
        </p>
        <SectionHeader title="Team building" />
        <p>
          Because the enemies you fight in the Swarm Disaster mode are not only
          hard mechanic wise, but also their levels can go beyond that what
          we've fought against so far (up to level 95 at the highest
          difficulty), we{' '}
          <strong>
            suggest going back to the 'release' team building meta and focus on
            the defensive side of your team more
          </strong>
          . This will make your runs much more stable, especially on auto-play.
        </p>
        <p>
          Furthermore, because the Blessings and passive buffs offer a lot of
          DMG% or ATK% increases for your team,{' '}
          <strong>
            characters belonging to the Harmony category aren't that needed in
            this mode
          </strong>{' '}
          - the buffs provided by them get diluted. Instead, we suggest using a
          character who can provide{' '}
          <strong>
            DEF Shred because that's the only thing you can't gain in an easy
            way in this mode
          </strong>{' '}
          (this means that <strong>Silver Wolf and Pela</strong> will shine
          there).
        </p>
        <p>So the cookie cutter template for your team will look like this:</p>
        <ul>
          <li>
            <strong>DPS</strong> - the damage dealer of your choice,
          </li>
          <li>
            <strong>Def Shredder</strong> - either Silver Wolf or Pela,
          </li>
          <li>
            <strong>Healer</strong> - a reactive type support, so a character
            that will restore the lost Health - Natasha, Bailu, or Luocha,
          </li>
          <li>
            <strong>Shielder/Taunter</strong> - a proactive type support that
            helps the team mitigate the incoming damage via Shields or Taunts,
            so Fire Trailblazer, March 7th or Gepard.
          </li>
        </ul>
        <p>
          Keep in mind that the choice of your team will also impact the Path
          you will use as the additional synergy you will gain will make
          clearing the Swarm Disaster much easier.
        </p>
        <h6>Example composition for a new player</h6>
        <div className="custom-team-showcase">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="sushang"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="debuffer">Debuffer</h5>
            <div className="multi">
              <Card className="avatar-card">
                <HSRCharacter
                  slug="pela"
                  mode="icon"
                  showLabel
                  showIcon
                  enablePopover
                />
              </Card>
            </div>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Healer</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="natasha"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Shielder/Tank</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="trailblazer-preservation"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
        </div>
        <p>
          You can also replace the DEF Shredder character with a second DPS -
          with all the Blessings and Dice effects available in the mode, running
          double DPS characters actually is very viable:
        </p>
        <ul>
          <li>
            <strong>DPS</strong> - the damage dealer of your choice,
          </li>
          <li>
            <strong>DPS</strong> - the damage dealer of your choice,
          </li>
          <li>
            <strong>Healer</strong> - a reactive type support, so a character
            that will restore the lost Health - Natasha, Bailu, or Luocha,
          </li>
          <li>
            <strong>Shielder/Taunter</strong> - a proactive type support that
            helps the team mitigate the incoming damage via Shields or Taunts,
            so Fire Trailblazer, March 7th or Gepard.
          </li>
        </ul>
        <h6>Example composition for a new player</h6>
        <div className="custom-team-showcase">
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="sushang"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="main-dps">Main DPS</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="serval"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="healer">Healer</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="natasha"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
          <div className="custom-team-tab">
            <h5 className="shielder">Shielder/Tank</h5>
            <Card className="avatar-card">
              <HSRCharacter
                slug="trailblazer-preservation"
                mode="icon"
                showLabel
                showIcon
                enablePopover
              />
            </Card>
          </div>
        </div>
        <SectionHeader title="Best Blessings" />
        <p>
          This section contains generic Blessings that work with each path and
          will make your runs smoother:
        </p>
        <ul>
          <li>
            <strong className="hsr-rar rar-5">
              Regression Inequality of Annihilation
            </strong>{' '}
            (Destruction) - When player characters are hit, DMG taken by the
            characters is equally distributed among all allies,
          </li>
          <li>
            <strong className="hsr-rar rar-5">
              Perfect Experience: Reticence
            </strong>{' '}
            (Remembrance) - After an enemy is attacked 6 time(s), they have a
            120% base chance to become Frozen for 1 turn(s).
          </li>
          <li>
            <strong className="hsr-rar rar-5">
              Being of Abundance, Becoming One Mind
            </strong>{' '}
            (Abundance) - When the character provides healing, allies other than
            the healed target will also gain HP equal to 30% of the Outgoing
            Healing,
          </li>
          <li>
            <strong className="hsr-rar rar-3">
              Precious Moon-Like Candlelight
            </strong>{' '}
            (Abundance) - When a character provides healing to allies, increases
            the ATK of both units by 50% for 1 turn(s),
          </li>
          <li>
            <strong className="hsr-rar rar-3">Aversion to Suffering</strong>{' '}
            (Abundance) - After launching attacks, characters deal Additional
            DMG equal to 36% of their current HP,
          </li>
          <li>
            <strong className="hsr-rar rar-3">Clear Lucite Body</strong>{' '}
            (Abundance) - When a character's HP is full, reduces their DMG taken
            by 36%,
          </li>
          <li>
            <strong className="hsr-rar rar-3">
              Interstellar Construct: Sanctuary
            </strong>{' '}
            (Preservation) - At the end of the turn, there is 80% fixed chance
            for characters to gain a Shield equal to 12% of their Max HP,
            lasting for 1 turn(s),
          </li>
          <li>
            <strong className="hsr-rar rar-3">
              Battle Against the Old Foe
            </strong>{' '}
            (Hunt) - If a character has higher than 100% CRIT Rate and currently
            has Critical Boost, for every 1% CRIT Rate that exceeds 100%, CRIT
            DMG increases by 3%. CRIT DMG can be increased this way by up to
            150%,
          </li>
          <li>
            <strong className="hsr-rar rar-3">Auspicious Star</strong> (Hunt) -
            When a character defeats an enemy, regenerates Energy equal to 60%
            of the character's Max Energy,
          </li>
          <li>
            <strong className="hsr-rar rar-3">Ejecting the Borisin</strong>{' '}
            (Hunt) - After every 6 turn(s) conducted by all allies, the current
            character's action is Advanced Forward by 100%. The effect cannot be
            triggered repeatedly by the same character,
          </li>
          <li>
            <strong className="hsr-rar rar-3">All Things are Possible</strong>{' '}
            (Nihility) - If an enemy is attacked while under any DoT statuses, a
            random DoT will be triggered, dealing DMG equal to 100% of the DoT.
          </li>
          <li>
            <strong className="hsr-rar rar-3">
              Twenty-First Military Rule
            </strong>{' '}
            (Elation) - After a character launches follow-up attacks, there is a
            65% fixed chance to recover 1 Skill Point,
          </li>
          <li>
            <strong className="hsr-rar">Reflection</strong> (Destruction) - When
            struck with a killing blow, instead of becoming downed, characters
            immediately restore HP by 1% of their Max HP. The allied team can
            only trigger this effect 1 time(s) per battle,
          </li>
          <li>
            <strong className="hsr-rar">Blessing</strong> (Abundance) - After a
            character provides healing, there is a 30% fixed chance to recover 1
            Skill Point. This effect can only trigger once per action.
          </li>
        </ul>
        <SectionHeader title="Tips and tricks" />
        <p>
          Here's a list of various tips and tricks that will help you along your
          journey through the Swarm Disaster:
        </p>
        <ul>
          <li>
            Focus on doing the challenges in the Trail of Pathstrider as the
            bonus stats they provide will make your life much easier,
          </li>
          <li>
            You need to play on at least Difficulty 3 to be able to do the
            challenges in the Trail of Pathstrider (past the initial ones) -
            with some of them even requiring you to do them on Difficulty 4,
          </li>
          <li>
            Ensure you check the bonuses you're getting from the Communing Trail
            to avoid overcapping your stats e.g. if you have close to 100% Crit
            Rate normally look into switching a Crit piece as the Communing
            Trail gives you a further 18% at max rank,
          </li>
          <li>
            Initially, while you will be able to auto Difficulty 3 with a decent
            team, anything higher will require you to play on manual,
          </li>
          <li>
            Pick a Path that corresponds to the strengths of your account but
            also the goal you are trying to achieve. If you are trying to
            complete a Pathstrider mission that requires you kill Trotters
            choose Hunt, if you need to slay enemies in succession pick
            Destruction. All paths are viable so make sure to experiment!
          </li>
          <li>
            Make sure to use two defensive supports in your team to make your
            runs safer and smoother - use both a pro-active (Shielder/Taunt) and
            re-active (Healer) type of support for best effect,
          </li>
          <li>
            In Swarm Disaster you get massive increases to most of your combat
            stats like increased DMG, Crit Rate, Crit Dmg and ATK% - for this
            reason Harmony units are a little less valuable due to diminishing
            returns. DEF shred, RES shred and causing enemies to take increased
            damage is rare from Blessings and dice bonuses making Nihility units
            comparatively a little more valuable. So having a source of DEF
            Shred can drastically increase the damage output of your team (Pela
            and Silver Wolf are your options here),
          </li>
          <li>
            Consider running double DPS compositions. Thanks to the big stat
            buffs you can obtain from the Communing Trail and a lot of Blessings
            and Dice that give you a lot of Skill Points it's actually viable to
            play with two DPS in your team,
          </li>
          <li>
            Make sure you're on the look out for exceptionally powerful
            defensive Blessings even if they aren't a part of your Path. The
            main threat in Swarm disaster is being 1 shot or running out of HP
            before the enemy falls so they are always worth considering,
          </li>
          <li>
            Try to enable at least one Interplay bonus as the additional effect
            added to the Resonance skill will make a huge difference. And you
            can actually enable both of the Interplays in the same run!
          </li>
          <li>
            Imbibitor Lunae is the best character to use in the Trotter and vase
            smashing mini-events - his Technique allows him to zoom through the
            map effortlessly.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Video guide" />
        <p>
          For more tips and tricks and a generic overview for the mode we
          suggest watching these videos:
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="Cc_bEOInQi8" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="QL9Wiz7p5kI" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="Y0grl1GGgEw" />
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default HSRGuidesSwarm;

export const Head: React.FC = () => (
  <Seo
    title="Swarm Disaster Guide | Honkai: Star Rail | Prydwen Institute"
    description="This guide that will help you efficiently clear the Swarm Disaster by giving tips about characters, teams, Paths and Blessing you should use."
  />
);
